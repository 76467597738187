import { kumbh2025Messages } from '../../pages/page-kumbh-2025';
import { theCampMessages } from '../../pages/page-the-camp';

export const contentPage = {
  'camp67-content-page.read-more': 'Read more',
  'camp67-content-page.child.reading-time': 'minutes reading time',
};

export const tentPage = {
  'camp67-packages-page.subtitle': 'Choose from a breadth of accommodation options.',
  'camp67-packages-page.hero-image-alt':
    'A sunset view of a port in Varanasi, with boats on the River Ganges.',
  'camp67-packages-page.summary':
    'Learn more about the accommodation we offer and book now!',
  'camp67-packages-page-children.summary':
    'Browse the accommodation options we provide at camp67. Secure your spot now!',
  'camp67-tent-page.twin-room-summary': `<p>
  The twin room is best suited for couples, friends, and siblings. It is a
  comfortable, private tent-room, offering the perfect stay for you as you
  embark towards, and return from your daily venturing into the Kumbh grounds.
</p>
<paragraph>We encourage you to take a seat with a coffee from the camp67 café in hand. Talk through what you observed for the day with each other and plan the next!</paragraph>`,
  'camp67-tent-page.family-room-summary': `<p>
The most enjoyable part of travelling as a family is experiencing things
together. We've taken this seriously in the camp67 family cottage. A single
bed and a queen bed with an attached bunk await you, along with a generous
assortment of space.
</p>
<paragraph>While choosing where everyone sleeps may be an exercise (it always is,
isn't it…), we're confident that after overcoming that necessary
obstacle you'll feel right at home.</paragraph>`,
  'camp67-tent-page.family-room-plus-summary': `<p>
If you thought choosing your bed was tricky in a Family Cottage, wait till you
enter the Family Cottage Plus… Equipped with two queen beds and attached bunks,
this room is perfect for families and groups of six, all with your own suite
as well.
</p>
<paragraph>Share some quality family time with each other. And don't worry about
anything - your luggage is secure, your coffee is always warm & waiting for
you, etc…</paragraph>`,
  'camp67-tent-page.dorm-single-summary': `<p>
Looking to meet people as a solo-traveller, blogger or digital nomad? The Dorm
Room was made for you. Join others in a typical travel-hostel environment. Its
eight beds in a room, with that delicate balance between 'give me some
space' and 'I want to have a chat' which you'd be used
to as a solo-traveller.
</p>`,
  'camp67-tent-page.dorm-bunk-summary': `<p>
If you're looking for an even more traditional solo-traveller-experience;
grab a bunk in our larger dorm rooms! These rooms fit sixteen people and are
an excellent place to meet that one person who can tell you ghost stories all
night.
</p>
<paragraph>If you're travelling in a group, this may be a particularly interesting
option. If so, let us know, we'll get you sorted.</paragraph>`,
  'camp67-tent-page.back-to-packages': 'Back to all Packages',
  'camp67-tent-page.book-tent-now': 'Book this room now!',
};

export const contentPageMessages = {
  ...theCampMessages,
  ...kumbh2025Messages,
};
