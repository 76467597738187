import {
  oneOfAKind,
  weLoveKumbh,
  ourLocation,
  accommodationAndAmenities,
  tentAmenities,
  faq,
  getInTouch,
  experienceKumbh,
  joinNow,
  tents,
} from './namespaces/home';
import {
  banner,
  camp67Header,
  common,
  footer,
  links,
  navigation,
} from './namespaces/common';
import { contentPage, tentPage, contentPageMessages } from './namespaces/content';
import { allReservationMessages } from './namespaces/reservation';
import { contactMessages } from './namespaces/contact';
import { snackbarMessages } from './namespaces/snackbar';

const messages = {
  ...navigation,
  ...banner,
  ...camp67Header,
  ...links,
  ...common,
  ...oneOfAKind,
  ...weLoveKumbh,
  ...ourLocation,
  ...tents,
  ...accommodationAndAmenities,
  ...tentAmenities,
  ...faq,
  ...getInTouch,
  ...experienceKumbh,
  ...joinNow,
  ...footer,
  ...contentPage,
  ...tentPage,
  ...contentPageMessages,
  ...allReservationMessages,
  ...snackbarMessages,
  ...contactMessages,
};

export type MessageKey = keyof typeof messages;

export default messages;
